import React from 'react';
import Field from 'components/ui/form/field';
import { finalForm } from 'components/ui/form/final_form';
import FormInput from 'components/ui/form/input';
import FormSelect from 'components/ui/form/select';
import { email, required } from 'components/ui/form/validations';
import { Alert, Button, Form } from 'react-bootstrap';
import { useI18n } from 'services/i18n/hook';
import { SESSION_ENVS } from 'constants';

const SessionSignInForm = ({ invalid, submitting, handleSubmit, dirty }) => {
  const { t } = useI18n();
  const sessionExpired = false;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        autoFocus=""
        name="email"
        component={FormInput}
        type="email"
        autoComplete="email"
        validate={[required, email]}
        label={t('components.session.sign_in.form.email_label')}
      />
      <Field
        name="password"
        component={FormInput}
        type="password"
        validate={[required]}
        autoComplete="current-password"
        label={t('components.session.sign_in.form.password')}
      />
      <Field
        name="env"
        component={FormSelect}
        label={t('components.session.sign_in.form.environment')}
      >
        {Object.keys(SESSION_ENVS).map((env) => (
          <option value={env} key={env}>
            {SESSION_ENVS[env]}
          </option>
        ))}
      </Field>
      {sessionExpired && !dirty && (
        <Alert variant="danger">
          {t('components.session.sign_in.form.session_expired')}
        </Alert>
      )}
      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={invalid || submitting}
          onClick={handleSubmit}
        >
          {t('components.session.sign_in.form.sign_in')}
        </Button>
      </Form.Group>
    </form>
  );
};
export default finalForm(SessionSignInForm);
