import React, { useEffect, useRef, useState } from 'react';
import { Badge, Form, InputGroup, Table } from 'react-bootstrap';
import has from 'lodash/has';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import isString from 'lodash/isString';
import flattenDeep from 'lodash/flattenDeep';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import { useI18n } from 'services/i18n/hook';
import { parseForTranslations } from 'services/translations';
import StatsCard from 'components/ui/stats_card';
import classNames from 'classnames';
import { generateXLSX } from 'services/xlsx';
import Button from 'components/ui/button';
import WorkflowTranslationsRow from 'components/workflow/translations/row';
import { useWorkflow } from 'services/workflow/hook';
import { ViewportList } from 'react-viewport-list';
import MaxHeightContainer from 'components/ui/max_height_container';
import $ from 'jquery';
import { parseLanskyTranslationKeys } from 'services/schema/parse_lansky';
import { useSession } from 'services/sessions/hook';

export const getAllTranslationKeysFromSchema = (schema) => {
  const s = isString(schema) ? schema : JSON.stringify(schema);
  return s.match(/"t:(.*?)"/g) || [];
};

const getTranslationKeysFromLansky = async (
  workflow,
  computers,
  currentSessions,
  env,
) => {
  const computerContexts = uniqBy(
    flattenDeep(
      computers.map((computer) => {
        if (computer.computer_contexts.length > 0) {
          return computer.computer_contexts.map((computerContext) => {
            return computers.find((c) => c.id === computerContext.computer_id);
          });
        }
        return computer;
      }),
    ),
    'id',
  );
  let computersOfWorkflow = [workflow.validator_computer_id];
  workflow.steps.forEach((step) => {
    step.steps.forEach((subStep) => {
      subStep.blocks?.forEach((block) => {
        if (block.kind === 'premiums_table') {
          computersOfWorkflow.push(block.computer_id);
        }
      });
      step.blocks?.forEach((block) => {
        if (block.kind === 'premiums_table') {
          computersOfWorkflow.push(block.computer_id);
        }
      });
    });
  });
  computersOfWorkflow = uniq(compact(computersOfWorkflow));
  const computerList = uniq(
    compact(
      computersOfWorkflow.map((computerId) => {
        const computer = computers.find((c) => c.id === computerId);
        if (computer) {
          return computer;
        }
      }),
    ),
  );

  let keys = [];
  for (const computer of computerList) {
    const translationKeys = await parseLanskyTranslationKeys(
      computer,
      env,
      currentSessions,
      {
        computer_id: computer.id,
      },
    );
    keys = keys.concat(translationKeys);
  }
  for (const computerContext of computerContexts) {
    const translationKeys = await parseLanskyTranslationKeys(
      computerContext,
      env,
      currentSessions,
      {
        computer_id: computerContext.computer_id,
      },
    );
    keys = keys.concat(translationKeys);
  }
  return uniq(compact(keys)).map((key) => key.replace('t:', ''));
};

const WorkflowTranslationsTable = () => {
  const { t } = useI18n();
  const { locales, selectedWorkflow, env } = useWorkflow();
  const { workflow, computers } = selectedWorkflow;
  const { currentSessions } = useSession();
  const timeoutSearch = useRef(null);
  const ref = useRef(null);
  const [search, setSearch] = useState('');
  const [keysToRemove, setKeysToRemove] = useState([]);
  const [filters, setFilters] = useState({
    extra_validations: false,
    missing_translation_keys: false,
  });
  const [missingTranslationKeys, setMissingTranslationKeys] = useState([]);

  const [newTranslations, setNewTranslations] = useState({});
  const [translationsToDisplay, setTranslationsToDisplay] = useState([]);
  const [usedTranslationKeys, setUsedTranslationKeys] = useState([]);

  useEffect(() => {
    getTranslationKeysFromLansky(
      workflow,
      computers,
      currentSessions,
      env,
    ).then((keys) => {
      setNewTranslations((prevTranslations) => {
        return { ...prevTranslations, ...keys };
      });
      setTranslationsToDisplay((prevTranslations) => {
        return [...prevTranslations, ...keys];
      });
    });
    const allUsedTranslationKeys = sortBy(
      uniq(
        getAllTranslationKeysFromSchema(workflow).map((key) => {
          return key.replace('"t:', '').replace('"', '');
        }),
      ),
    ).filter((el) => el.trim() !== '');
    setUsedTranslationKeys(allUsedTranslationKeys);
    let allDefinedTranslations = parseForTranslations(workflow, locales);
    const missingKeys = allUsedTranslationKeys.filter(
      (key) => !has(allDefinedTranslations, key),
    );
    missingKeys.forEach((missingKey) => {
      allDefinedTranslations[missingKey] = {};
    });
    setNewTranslations(allDefinedTranslations);
    setTranslationsToDisplay(sortBy(Object.keys(allDefinedTranslations)));
    setMissingTranslationKeys(missingKeys);
  }, []);

  const definedTranslationKeys = sortBy(Object.keys(newTranslations));
  const usedCount = usedTranslationKeys.length;
  const translationCount = definedTranslationKeys.length;
  const tooMuchTranslationDefined = translationCount > usedCount;

  const onSearch = (newSearch) => {
    setSearch(newSearch);
    onFilterChange(newSearch, filters);
  };

  const onFilterChange = (searchToUse, filterToUse) => {
    if (timeoutSearch.current) {
      clearTimeout(timeoutSearch.current);
    }
    timeoutSearch.current = setTimeout(() => {
      setTranslationsToDisplay(() => {
        return sortBy(
          Object.keys(newTranslations).filter((key) =>
            searchToUse.length > 0 ? key.includes(searchToUse) : true,
          ),
        )
          .filter((key) => {
            return filterToUse.extra_validations
              ? !usedTranslationKeys.includes(key)
              : true;
          })
          .filter((key) => {
            return filterToUse.missing_translation_keys
              ? missingTranslationKeys.includes(key)
              : true;
          });
      });
    }, 300);
  };

  const toggleFilter = (key) => {
    const newFilters = {
      ...filters,
      [key]: !filters[key],
    };
    setFilters(newFilters);
    onFilterChange(search, newFilters);
  };

  const onTranslationChange = ({ initialKey, key, fr, nl }) => {
    let updatedTranslations = {
      ...newTranslations,
      [key]: {
        fr,
        nl,
      },
    };
    updatedTranslations =
      initialKey === key
        ? updatedTranslations
        : omit(updatedTranslations, [initialKey]);
    setNewTranslations(updatedTranslations);
    setTranslationsToDisplay(sortBy(Object.keys(updatedTranslations)));
  };

  const toggleKeyToRemove = (key) => {
    setKeysToRemove((prevKeys) => {
      if (prevKeys.includes(key)) {
        return prevKeys.filter((prevKeyToRemove) => prevKeyToRemove !== key);
      } else {
        return [...prevKeys, key];
      }
    });
  };

  const removeKeys = () => {
    const updatedTranslations = omit(newTranslations, keysToRemove);
    setNewTranslations(updatedTranslations);
    setTranslationsToDisplay(sortBy(Object.keys(updatedTranslations)));
    setKeysToRemove([]);
  };

  return (
    <>
      <div className="d-flex" id="translation-filter-bar">
        <StatsCard
          title={t(
            'components.workflow.translations.table.workflow_vs_defined',
          )}
        >
          {usedCount}
          {' / '}
          <span className={tooMuchTranslationDefined ? 'text-warning' : ''}>
            {translationCount}
          </span>
        </StatsCard>
        {tooMuchTranslationDefined && (
          <StatsCard
            title={t(
              'components.workflow.translations.table.extra_translations',
            )}
            className={classNames('cursor-pointer', {
              'bg-200': filters.extra_validations,
            })}
            onClick={() => {
              toggleFilter('extra_validations');
            }}
          >
            <span className="text-warning">{translationCount - usedCount}</span>
          </StatsCard>
        )}
        {missingTranslationKeys.length > 0 && (
          <StatsCard
            title={t(
              'components.workflow.translations.table.missing_translation_key',
            )}
            className={classNames('cursor-pointer', {
              'bg-200': filters.missing_translation_keys,
            })}
            onClick={() => {
              toggleFilter('missing_translation_keys');
            }}
          >
            <span className="text-danger">{missingTranslationKeys.length}</span>
          </StatsCard>
        )}
        <div className="ms-auto d-flex align-items-center">
          {keysToRemove.length > 0 && (
            <Button
              icon={['fa', 'trash']}
              size="sm"
              variant="danger"
              className="me-2 d-flex align-items-center"
              onClick={removeKeys}
            >
              {t('components.workflow.translations.table.delete_selected_keys')}
              <Badge pill bg="light" className="ms-2 text-danger">
                {keysToRemove.length}
              </Badge>
            </Button>
          )}
          <Button
            icon={['fa', 'file-excel']}
            size="sm"
            onClick={() => generateXLSX(newTranslations, workflow, locales)}
            className="me-2"
          >
            {t('components.workflow.translations.table.generate_xlsx')}
          </Button>
        </div>
      </div>
      <MaxHeightContainer
        id="translation-table"
        ref={ref}
        offset={
          $('#translation-card-header').height() +
          $('#translation-filter-bar').height()
        }
      >
        <Table size="sm" bordered hover className="fs--1">
          <thead className="bg-200 fw-bold">
            <tr className="align-middle">
              <th>
                <Form.Check
                  checked={keysToRemove.length === translationsToDisplay.length}
                  type="checkbox"
                  onChange={(ev) => {
                    if (ev.target.checked) {
                      setKeysToRemove(translationsToDisplay);
                    } else {
                      setKeysToRemove([]);
                    }
                  }}
                />
              </th>
              <th>
                <div className="d-flex align-items-center">
                  {t('components.workflow.translations.table.key')}
                  <InputGroup className="ms-5">
                    <Form.Control
                      size="sm"
                      placeholder={t('components.request.data.table.search')}
                      value={search}
                      onChange={(ev) => onSearch(ev.target.value)}
                    />
                  </InputGroup>
                </div>
              </th>
              {locales.map((locale) => (
                <th className="w-30" key={locale}>
                  {t(`global.locales.${locale}`)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <ViewportList
              viewportRef={ref}
              items={translationsToDisplay}
              renderSpacer={({ ref, style }) => <tr style={style} ref={ref} />}
            >
              {(translationKey) =>
                translationKey ? (
                  <WorkflowTranslationsRow
                    selectedKeys={keysToRemove}
                    toggleKey={toggleKeyToRemove}
                    key={translationKey}
                    translationKey={translationKey}
                    allTranslations={newTranslations}
                    missingTranslationKeys={missingTranslationKeys}
                    usedTranslationKeys={usedTranslationKeys}
                    search={search}
                    onTranslationChange={onTranslationChange}
                    locales={locales}
                  />
                ) : null
              }
            </ViewportList>
          </tbody>
        </Table>
      </MaxHeightContainer>
    </>
  );
};

export default WorkflowTranslationsTable;
