import axios from 'axios';
import Cookies from 'js-cookie';
import merge from 'lodash/merge';
import { getCookieLocale } from 'services/i18n/provider';
import { toast } from 'react-toastify';
import {
  getAuthorizationHeader,
  deleteAuthorizationToken,
} from 'services/sessions/authorization_token';
import {
  PROTOCOL,
  DEFAULT_LOCALE,
  APP_DOMAIN,
  BYPASS_MAINTENANCE_COOKIE_NAME,
  API_DOMAINS,
} from 'constants';

const setHeaders = (env, existingHeaders = {}) => {
  const headers = {
    ...existingHeaders,
    Authorization: getAuthorizationHeader(env),
    Locale: window.locale || getCookieLocale() || DEFAULT_LOCALE,
    Accept: 'application/json',
  };
  if (Cookies.get(BYPASS_MAINTENANCE_COOKIE_NAME) === '1') {
    merge(headers, {
      'X-Bypass-Maintenance': true,
    });
  }
  return headers;
};

const onRejected = (error, env) => {
  if (error.response && error.response.status === 503) {
    if (window.setMaintenance) {
      window.setMaintenance();
    }
  }

  if (error.response && error.response.status === 401) {
    const token = getAuthorizationHeader(env);
    if (token) {
      deleteAuthorizationToken(env);
    }
    if (window.askSignInFor) {
      window.askSignInFor(env);
      return Promise.reject(error);
    }
    if (!location.href.includes('/?session_expired')) {
      location.href = `${PROTOCOL}://${APP_DOMAIN}/?session_expired`;
      return Promise.reject(error);
    }
  }

  if (error.response) {
    return Promise.reject(error.response);
  }

  return Promise.reject(error);
};

const onFullFilled = (response) => {
  return response;
};

const createInstance = (env) => {
  const instance = axios.create({
    baseURL: API_DOMAINS[env],
  });

  instance.interceptors.request.use(
    (config) => {
      if (!getAuthorizationHeader(env) && config.url !== 'sessions') {
        toast.error(`Not signed in for ${env}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        if (window.askSignInFor) {
          window.askSignInFor(env);
        }
        return Promise.reject(`Not signed in for ${env}`);
      }
      config.headers = setHeaders(env, config.headers);
      return config;
    },
    (error) => {
      return Promise.reject(error, env);
    },
  );

  instance.interceptors.response.use(onFullFilled, onRejected);
  return instance;
};

class ApiInstances {
  constructor() {
    this.instances = {
      development: null,
      staging: null,
      validation: null,
      production: null,
    };
    this.#initInstances();
  }

  use(env) {
    return this.instances[env];
  }

  #initInstances() {
    Object.keys(API_DOMAINS).map((env) => {
      this.instances[env] = createInstance(env);
    });
  }
}

export default new ApiInstances();
