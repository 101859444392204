import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Button from 'components/ui/button';
import isNil from 'lodash/isNil';
import { useLansky } from 'services/lansky/hook';
import { useI18n } from 'services/i18n/hook';
import { useFile } from 'services/file/hook';
import EnvironmentSelectField from 'components/environment/select_field';
import axios from 'axios';

const LanskySelectorForm = () => {
  const { t } = useI18n();
  const { selectFile } = useFile();
  const [file, setFile] = useState(null);
  const { loading, setEnv, env, runLansky, setSLSResult, setSLSError } =
    useLansky();
  const [slsUrl, setSlsUrl] = useState('');
  const getFile = async () => {
    const selectedFile = await selectFile((file) => runLansky(file));
    if (selectedFile) {
      setFile(selectedFile);
    }
  };
  const compute = () => {
    runLansky(file);
  };

  const computeAndSendToSLS = () => {
    runLansky(file).then(({ __debug, ...data }) => {
      axios
        .post(
          slsUrl,
          { data },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            auth: {
              username: 'insurgate',
              password: 'insurgate',
            },
          },
        )
        .then((response) => {
          console.log('computeAndSendToSLS', response);
          setSLSResult(response.data);
        })
        .catch((error) => {
          const errorData = error.response?.data || error.message;
          console.log('errorData', errorData);
          console.error('computeAndSendToSLS', error);
          setSLSError(errorData);
        });
    });
  };
  return (
    <>
      <div className="p-2 bg-200">
        <InputGroup className="mb-2">
          <Button
            variant="secondary"
            size="sm"
            onClick={getFile}
            icon={['fa', 'file-excel']}
          >
            {t('components.lansky.selector.form.select_file')}
          </Button>
          <Form.Control
            size="sm"
            placeholder={t(
              'components.lansky.selector.form.no_computer_selected',
            )}
            value={file ? file.name : ''}
            disabled
            onChange={() => {}}
          />
        </InputGroup>
        <InputGroup className="mb-2">
          <EnvironmentSelectField env={env} setEnv={setEnv} loading={loading} />
          <Button
            variant="primary"
            size="sm"
            onClick={() => compute()}
            disabled={isNil(file)}
            icon={['fa', 'calculator']}
          >
            {t('components.lansky.selector.form.compute')}
          </Button>
        </InputGroup>
        <InputGroup>
          <Form.Control
            type="text"
            size="sm"
            placeholder="SLS URL"
            value={slsUrl}
            onChange={(e) => setSlsUrl(e.target.value)}
          />
          <Button
            variant="primary"
            size="sm"
            onClick={() => computeAndSendToSLS()}
            disabled={isNil(file) || isNil(slsUrl)}
            icon={['fa', 'calculator']}
          >
            {t('components.lansky.selector.form.compute_and_send_to_sls')}
          </Button>
        </InputGroup>
      </div>
    </>
  );
};

export default LanskySelectorForm;
