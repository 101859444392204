import React, { useState } from 'react';
import { Button, Tab, Tabs, Alert } from 'react-bootstrap';
import { useI18n } from 'services/i18n/hook';
import { useLansky } from 'services/lansky/hook';
import 'react-json-pretty/themes/acai.css';
import JsonViewer from 'components/ui/json_viewer';
import LanskyIntegrityChecker from './integrity_checker';
import EmptyLanskyImage from 'images/lansky_empty.svg';
import EmptyState from 'components/ui/empty_state';
import isEmpty from 'lodash/isEmpty';
import { DEVELOPMENT } from 'constants';
const LanskyResults = () => {
  const { t } = useI18n();
  const [key, setKey] = useState('results');
  const { results, onlyLanskyInputs, toggleLanksyInputs, slsResult, slsError } =
    useLansky();
  const [collapse, setCollapse] = useState(false);
  const collapseAll = () => setCollapse((prev) => !prev);
  if (isEmpty(results)) {
    return (
      <EmptyState
        image={EmptyLanskyImage}
        text={t('components.lansky.card.select_computer_and_compute')}
      />
    );
  }
  const { __debug, ...restResults } = results;

  return (
    <div className="p-2">
      <Button onClick={collapseAll} size="sm" variant="secondary">
        {collapse
          ? t('components.lansky.results.open_all')
          : t('components.lansky.results.collapse_all')}
      </Button>
      {DEVELOPMENT && (
        <Button onClick={toggleLanksyInputs} size="sm" variant="secondary">
          {onlyLanskyInputs
            ? t('components.lansky.results.show_only_lansky_inputs')
            : t('components.lansky.results.show_all_data')}
        </Button>
      )}
      {false && <LanskyIntegrityChecker />}
      <Tabs
        defaultActiveKey="profile"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab
          eventKey="results"
          title={t('components.lansky.results.results')}
          className="fs-1"
        >
          <JsonViewer json={restResults} collapse={collapse} />
        </Tab>
        <Tab eventKey="inputs" title={t('components.lansky.results.inputs')}>
          <JsonViewer json={__debug} collapse={collapse} />
        </Tab>
        <Tab eventKey="sls" title={t('components.lansky.results.sls')}>
          {slsError ? (
            <Alert variant="danger">
              <pre>{JSON.stringify(slsError, null, 2)}</pre>
            </Alert>
          ) : (
            slsResult && <JsonViewer json={slsResult} collapse={collapse} />
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default LanskyResults;
