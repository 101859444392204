import React from 'react';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import Loader from 'components/ui/loader';
import { useI18n } from 'services/i18n/hook';
import WatsonSelectorForm from 'components/watson/selector/form';
import { useWatson } from 'services/watson/hook';
import WatsonDocumentation from 'components/watson/documentation';
import WatsonPreview from './preview';

const WatsonCard = () => {
  const { t } = useI18n();
  const { runAt, errors, loading, loadingTranslations } = useWatson();
  return (
    <Card>
      <Card.Header className="bg-light">
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <div>
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {t('components.watson.card.template_title')}
              </h5>
              <small>
                {runAt && (
                  <>
                    {t('components.watson.card.generated_at')}
                    <Moment from={runAt} format="DD/MM/YYYY HH:mm" />
                  </>
                )}
              </small>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        {loadingTranslations ? <Loader /> : <WatsonSelectorForm />}
        <div className="p-2 fs--1">
          {loading ? (
            <Loader />
          ) : (
            <>
              {errors ? (
                <Alert variant="danger" className="mt-2">
                  {t('components.watson.card.error_occurred')}
                  <pre>{JSON.stringify(errors, null, 2)}</pre>
                </Alert>
              ) : (
                <>
                  <WatsonPreview />
                  <WatsonDocumentation />
                </>
              )}
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default WatsonCard;
