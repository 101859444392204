import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Buffer } from 'buffer/';
import { WATSON_ENVS, LOCALES, DEFAULT_ENV } from 'constants';
import { readFileIntoDataURI, saveDataToFile } from 'services/file';
import WatsonContext from 'services/watson/context';
import { useRequest } from 'services/request/hook';
import { useSession } from 'services/sessions/hook';
import { useWorkflow } from 'services/workflow/hook';
import { getAllTranslations } from 'services/translations';
import { uploadToS3 } from 'services/s3';

const base64ToBlob = (base64, mimeType) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], { type: mimeType });
};

const WatsonProvider = ({ children }) => {
  const { request, env: requestEnv } = useRequest();
  const [env, setEnv] = useState(DEFAULT_ENV);
  const [previewURL, setPreviewURL] = useState(null);
  const [translations, setTranslations] = useState({});
  const [loadingTranslations, setLoadingTranslations] = useState(false);
  const [locale, setLocale] = useState(Object.keys(LOCALES)[0]);
  const [errors, setErrors] = useState(null);
  const [generationWarnings, setGenerationWarnings] = useState([]);
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [runAt, setRunAt] = useState(false);
  const { currentSessions } = useSession();
  const { setSelectedWorkflow } = useWorkflow();

  useEffect(() => {
    if (request) {
      setLoadingTranslations(true);
      setSelectedWorkflow(request.workflow_id, requestEnv)
        .then(({ workflow }) => {
          const translationsOfWorkflow = getAllTranslations(workflow);
          setTranslations(translationsOfWorkflow);
          setLoadingTranslations(false);
        })
        .catch((error) => {
          setLoadingTranslations(false);
        });
    }
  }, [request]);

  const reset = () => {
    setErrors(null);
    setGenerationWarnings([]);
    setResults(null);
  };

  const uploadFile = (document, onError) => {
    const mimeType =
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    const docxBlob = base64ToBlob(document, mimeType);
    uploadToS3({
      file: docxBlob,
      onProgressChange: (progress) => {},
    })
      .then((documentURL) => {
        setPreviewURL(documentURL);
      })
      .catch((error) => {
        onError();
      });
  };

  const saveFile = (document, contentType) => {
    saveDataToFile(Buffer.from(document, 'base64'), 'report.docx', contentType);
  };

  const runWatson = async (file, upload = false, localeToUse) => {
    setLoading(true);
    reset();
    let dataURI;

    try {
      dataURI = await readFileIntoDataURI(file);
    } catch (e) {
      setLoading(false);
      throw new Error('Re-sélectionnez le fichier');
    }
    try {
      if (!dataURI) {
        throw new Error('Re-sélectionnez le fichier');
      }
      const response = await axios.post(
        `${WATSON_ENVS[env].path}/document`,
        {
          dataURI,
          data: {
            ...request,
            translations,
          },
          document_template: {
            locale: localeToUse || locale,
          },
          debug: true,
        },
        {
          headers: {
            Authorization: `Bearer ${currentSessions[env].authorizationToken}`,
            Environment: env,
          },
        },
      );
      if (response.data.errors) {
        setGenerationWarnings(response.data.errors);
      }
      setRunAt(Date.now());
      if (upload) {
        uploadFile(response.data.document, () => {
          saveFile(response.data.document, response.headers['content-type']);
        });
      } else {
        saveFile(response.data.document, response.headers['content-type']);
      }
      setLoading(false);
      return response.data.document;
    } catch (e) {
      console.log('runWatson got ERROR', e);
      console.error(e);
      setErrors(e.response?.data || e.message);
      setLoading(false);
    }
  };

  const checkTemplate = async (file) => {
    reset();
    const dataURI = await readFileIntoDataURI(file);
    try {
      const result = await axios.post(
        `${WATSON_ENVS[env].path}/check_template`,
        {
          dataURI,
        },
        {
          headers: {
            Authorization: `Bearer ${currentSessions[env].authorizationToken}`,
            Environment: env,
          },
        },
      );
      setGenerationWarnings(result.data.check.errors);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <WatsonContext.Provider
      value={{
        runWatson,
        checkTemplate,
        generationWarnings,
        errors,
        results,
        loading: loading,
        loadingTranslations,
        setEnv,
        setLocale: (localeToSet) => {
          setLocale(localeToSet);
        },
        locale,
        env,
        runAt,
        reset,
        previewURL,
      }}
    >
      {children}
    </WatsonContext.Provider>
  );
};

export default WatsonProvider;
