import React, { useEffect, useState, useRef } from 'react';
import SessionContext from 'services/sessions/context';
import Loader from 'components/ui/loader';
import {
  getAuthorizationToken,
  setAuthorizationToken,
} from 'services/sessions/authorization_token';
import { getSession } from 'requests/sessions';
import { useI18n } from 'services/i18n/hook';
import { SESSION_ENVS } from 'constants';
import every from 'lodash/every';
import values from 'lodash/values';
import isNil from 'lodash/isNil';
import { Modal } from 'react-bootstrap';
import SessionSignInContainer from 'components/session/sign_in/container';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from 'constants';

let sessionEnvs = {};
Object.keys(SESSION_ENVS).map((env) => {
  sessionEnvs[env] = null;
});
const SessionProvider = ({ children }) => {
  const { setCurrentLocale, t, getCurrentLocale } = useI18n();
  const [loading, setLoading] = useState(true);
  const [sessions, setSession] = useState(sessionEnvs);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [forEnv, setForEnv] = useState(null);
  const onSignInSuccessCallback = useRef(null);

  const keepSession = (response, env) => {
    const authorizationToken = response.headers.authorization.split(' ')[1];
    setAuthorizationToken(authorizationToken, env);
    setCurrentLocale('en' /*response.data.user.locale*/);
    setSession((prevSessions) => {
      return {
        ...prevSessions,
        [env]: {
          ...response.data,
          authorizationToken,
        },
      };
    });
  };

  const checkForSession = (env) => {
    if (!getAuthorizationToken(env)) {
      return Promise.resolve();
    }
    return getSession(env)
      .then((response) => {
        keepSession(response, env);
      })
      .catch((e) => {
        console.error('e', e);
      });
  };

  const deleteSession = (env) => {
    setAuthorizationToken(null, env);
    setSession((prevSessions) => {
      return {
        ...prevSessions,
        [env]: null,
      };
    });
  };

  const askSignInFor = (forEnv, onSignInSuccess) => {
    setShowSignInModal(true);
    setForEnv(forEnv);
    onSignInSuccessCallback.current = onSignInSuccess;
  };

  useEffect(() => {
    setLoading(true);
    const checkPromises = Object.keys(SESSION_ENVS).map((env) => {
      return checkForSession(env);
    });
    Promise.all(checkPromises).then(() => {
      setLoading(false);
    });
    window['askSignInFor'] = askSignInFor;
  }, []);

  if (loading) {
    return <Loader />;
  }

  const currentLocale = getCurrentLocale();

  return (
    <SessionContext.Provider
      value={{
        isSignedIn: !every(values(sessions), isNil),
        currentSessions: sessions,
        getFirstSession: () => {
          const firstEnvWithSession = Object.keys(SESSION_ENVS).find((env) => {
            if (sessions[env]) {
              return sessions[env];
            }
          });
          return sessions[firstEnvWithSession];
        },
        keepSession,
        deleteSession,
        askSignInFor,
      }}
    >
      <GoogleReCaptchaProvider
        reCaptchaKey={RECAPTCHA_SITE_KEY}
        language={currentLocale}
      >
        {children}
        <Modal show={showSignInModal} onHide={() => setShowSignInModal(false)}>
          <Modal.Header>
            <Modal.Title>
              {t('services.sessions.provider.modal.sign_in')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SessionSignInContainer
              env={forEnv}
              onSignInSuccess={() => {
                setShowSignInModal(false);
                setForEnv(null);
                onSignInSuccessCallback.current &&
                  onSignInSuccessCallback.current();
                onSignInSuccessCallback.current = null;
              }}
            />
          </Modal.Body>
        </Modal>
      </GoogleReCaptchaProvider>
    </SessionContext.Provider>
  );
};

export default SessionProvider;
