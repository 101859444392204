import Api from 'services/api';

export const getSession = (env) => {
  return new Promise((resolve, reject) => {
    Api.use(env)
      .get('sessions')
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createSession = ({ env, ...attributes }) => {
  return new Promise((resolve, reject) => {
    Api.use(env)
      .post(
        'sessions',
        { session: attributes },
        {
          headers: {
            NoCaptcha: 'no-captcha-for-me-im-a-good-bot-1348@!',
          },
        },
      )
      .then((response) => {
        resolve({ response });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
